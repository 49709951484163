import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ShopifyLogo from "../../../components/images/shopify"
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import SectionNavigate from '../../../components/widgets/sectionNavigate';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyConfigurarImpuestos: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <ShopifyLogo />
            </div>
            <h2>
                Configurar impuestos
            </h2>
            <p>
            Tus impuestos se obtienen directamente de la configuración de tu producto, por lo que el <b>total desglozado cobrado en Shopify con impuestos será el total que verás en tu factura</b>.
            </p>
            <ImageFooted explanation={`Primero, hay que revisar la configuración de impuestos de Shopify, para entrar a esta vista selecciona "Settup" en el menu de Shopify Admin de la izquierda abajo, después "Taxes".`}>
                <IMG_Shopify file={'settupTaxes'} />
            </ImageFooted>
            <p>
                La opción de <b>"Show prices with tax included"</b> es importante, esto indica si el costo de un producto de $100 ya incluye impuestos ($13.79 IVA) o si el precio de $100 se le agregan impuestos ($16 de IVA). <br />
                <b>Shopify cobrará impuestos siempre que el producto sea gravable,</b> la diferencia es que si declaras un producto con precio sin impuesto de $100, el comprador verá $116 en tu tienda, de lo contrario verá $100.
            </p>
            <p>
                Es importante saber, que el IVA al ser del 16%, <b>no siempre es completamente divisible sin redondeos.</b> <b><span className="accent">Por lo que al configurar precios con impuestos incluídos es posible ver diferencias de centavos entre la factura final y el pedido.</span> </b> <br />
                Si te es muy importante que tu factura sea precisa, el precio de los productos no deben de incluír impuesto, de esta forma se le agrega sin problemas de división redondeada.
            </p>
            <h3>
                Configurar los impuestos en tus productos
            </h3>
            <div className="row two centered">
                <ImageFooted explanation={`Para que un producto grave IVA, debes de seleccionar que el producto grava impuesto en esta sección al crear o editar el producto.`}>
                    <IMG_Shopify file={'productTax'} />
                </ImageFooted>
                <ImageFooted explanation={`Los productos sin inventario ("Custom Products"), se declara si gravan o no impuestos en el pedido directamente.`}>
                    <IMG_Shopify file={'orderTax'} />
                </ImageFooted>
            </div>
            <p>
                Con las configuraciónes mostradas arriba, los productos al facturar gravarán el 16% de IVA, si deseas que los productos sean excemptos de IVA tales como comida, estas casillas deben estar en blanco.
            </p>
            <h3>
                Impuestos especiales (IEPS)
            </h3>
            <p>
                A diferencia del IVA, <b>Shopify no tiene concepto de IEPS.</b> Por esta razón, este impuesto si se configura dentro de FiscalPOP. 
            </p>
            <ImageFooted explanation={`El IEPS es un impuesto antes de IVA, por lo que se desglosa del precio unitario antes de IVA.`}>
                <IMG_Shopify file={'productIEPS'} />
            </ImageFooted>
            <pre>
                <code>
<b>Ejemplo con Producto 1: Mezcal producido en planta </b><br /><br />

<b>Precio con impuesto incluído</b> en Shopify: $100<br />
- IEPS: 53%<br />
- IVA: 16%<br /><br />

// El IEPS se toma como parte del valor unitario del producto<br />
$100 = 1.16 x (Precio unitario con IEPS)<br />
$100 / 1.16 = (Precio unitario con IEPS)<br />
<b>(Precio unitario con IEPS)</b> = $86.21<br /><br />

<b><span className="accent">
(Precio unitario) =  (Precio unitario con IEPS) / 1.53<br /><br />
</span></b>

Precio Unitario = $56.34<br />
IEPS = $29.86<br />
IVA = $13.79<br /><br />

Subtotal del precio Base (Cobrado sin impuestos por Shopify) = $56.34 + $29.86 = $86.20<br />
<b>Total con impuesto (IVA e IEPS) = $86.20 + ($13.79)  = $99.99 (diferencia de 1 centavo)</b><br />
                </code>                
            </pre>
            <SectionNavigate previous={`/ecommerce/shopify/facturacion-reembolso`} next={`/ecommerce/shopify/claves-sat`}/>

        </div>
    </Layout>
)

export default ShopifyConfigurarImpuestos;